import React from "react"
import styled from "styled-components"

// TODO - PAGINATION
export default function Layout7030({
    title, contentMiddle, pagination,
    articlesBelfold, articlesKulfold, articlesGazdasag,
    articlesKultura, articlesSport, articlesBulvar, articlesIdojaras
}) {
    const Style70 = styled.div`width: 70%;`
    const Style30 = styled.div`width: 30%;`

    return (
        <>
            <div className="title_news_block title_news_block_3">
                <div className="container-fluid">
                    <span className="third">{ title }</span>
                </div>
            </div>
            <div className="wrap_news_block">
                <div className="container-fluid">
                    <div className="block-part">
                        <div className="row row-glairy">
                            <Style70 className="col-glairy col-xs-12 col-md-8">
                                <div className="authors_contents_int">
                                    <div className="row row-glairy display-block">
                                        { contentMiddle }                                        
                                    </div>
                                </div>
                                { pagination }
                            </Style70>
                            <Style30 className="col-glairy col-xs-12 col-md-4">
                                <div className="block-aside-part block-aside-int-part">
                                    <div className="row row-glairy">
                                        <div className="col-glairy col-xs-12 col-md-12">
                                            <div className="row row-glairy">
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-1">
                                                    <div className="box box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Belföld</span>
                                                        </div>
                                                        <div className="box-content">
                                                            { articlesBelfold }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-2">
                                                    <div className="box box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Külföld</span>
                                                        </div>
                                                        <div className="box-content">
                                                            { articlesKulfold }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-4">
                                                    <div className="box box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Gazdaság</span>
                                                        </div>
                                                        <div className="box-content">
                                                            { articlesGazdasag }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-7">
                                                    <div className="box box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Kultúra</span>
                                                        </div>
                                                        <div className="box-content">
                                                            { articlesKultura }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-8">
                                                    <div className="box box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Sport</span>
                                                        </div>
                                                        <div className="box-content">
                                                            { articlesSport }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-9">
                                                    <div className="box box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Bulvár</span>
                                                        </div>
                                                        <div className="box-content">
                                                            { articlesBulvar }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-glairy col-xs-12 col-md-12 col-box-11">
                                                    <div className="box box-big-labeled">
                                                        <div className="box-headline">
                                                            <span>Időjárás</span>
                                                        </div>
                                                        <div className="box-content">
                                                            { articlesIdojaras }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Style30>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}