import React from "react"
import { Link } from "gatsby"

export default function PaginationButton({ prev, next }) {
    return (
        <>
            <div className="text-center">
                <ul className="pagination">
                    { !prev && next && <li><a href={next}>További híreink »</a></li> }
                    { !next && prev && <li><a href={prev}>« Előző</a></li> }
                    { next && prev && <>
                        <li><a href={prev}>« Előző</a></li>
                        <li><a href={next} style={{ marginLeft: `10px` }}>Következő »</a></li>
                    </> }
                </ul>
            </div>
        </>
    )
}