import React from "react"
import LayoutSite from "./layouts/layoutSite"
import Layout7030 from "./layouts/layout7030"
import Boxes from "./crumbs/boxes"
import PageSeo from "./crumbs/pageSeo"
import PaginationButton from "./crumbs/paginationButton"
import { graphql } from "gatsby"
import BoxAd from "./crumbs/boxAd"

const getCover = (article) => {
    if (article.cover === null || article.cover.childImageSharp === null || article.cover.childImageSharp.fluid === null) {
        return null
    } else {
        return article.cover.childImageSharp.fluid
    }
}

export default function SearchTags({ pageContext, data }) {
    const title = pageContext.category
    const topNews = pageContext.topNews
    const prev = pageContext.prev
    const next = pageContext.next

    const belfold = topNews.belfold.edges.map(item =>   <Boxes box={4} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={null} />)    
    const kulfold = topNews.kulfold.edges.map(item =>   <Boxes box={4} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={null} />)
    const gazdasag = topNews.gazdasag.edges.map(item => <Boxes box={4} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={null} />)
    const kultura = topNews.kultura.edges.map(item =>   <Boxes box={4} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={null} />)
    const sport = topNews.sport.edges.map(item =>       <Boxes box={4} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={null} />)
    const bulvar = topNews.bulvar.edges.map(item =>     <Boxes box={4} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={null} />)
    const idojaras = topNews.idojaras.edges.map(item => <Boxes box={4} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={null} />)

    const contentMiddle = data.articles.edges.map(item => <Boxes box={18} title={item.node.title} excerpt={item.node.excerpt} category={item.node.category.name} cover={getCover(item.node)} />)
    const pagination = <PaginationButton next={next} prev={prev} />

    return (
        <>
            <PageSeo
                title="Minden Percben | Érdekes"
                description="Minden amiről érdemes tudnia hazánkban és a nagyvilágban. Mert minden perc érdekes!"
                keywords="hírek, érdekességek"
                canonical="https://www.mindenpercben.hu"
                canonicalMobile = "https://m.mindenpercben.hu/"
            />
            <LayoutSite>            
                <Layout7030
                    title={title}
                    contentMiddle={contentMiddle}
                    pagination={pagination}
                    articlesBelfold={belfold}
                    articlesKulfold={kulfold}
                    articlesGazdasag={gazdasag}
                    articlesKultura={kultura}
                    articlesSport={sport}
                    articlesBulvar={bulvar}
                    articlesIdojaras={idojaras}
                />
            </LayoutSite>
        </>
    )
}

export const pageQuery = graphql`
    query GetArticlesByCategoriesPaginated($skip: Int!, $limit: Int!, $category: String!) {
        articles: allStrapiArticle(skip: $skip, limit: $limit, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: $category}}}) {
            edges {
                node {
                    title
                    excerpt
                    cover {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                    }
                    category {
                        name
                    }
                }
            }
        }
    }
`